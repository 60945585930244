// Context.js
import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import queryString from 'query-string';
import {v4 as uuid} from "uuid";
import UtilService from "../services/UtilService";


const Context = React.createContext([{}, () => {
}]); // Our empty Context ready.

const StateContext = props => {
    const location = useLocation();
    let adParam = queryString.parse(location.search);
    let AdId = null;
    let UtmId = null;
    let UtmCampaign = null;
    let UtmMedium = null;
    let UtmContent = null;
    let UtmTerm = null;
    let UtmSource = null;
    let UtmSourcePlatform = null;
    let UtmCreativeFormat= null;
    let UtmMarketingTactic = null;
    let UtmMarketingObjective = null;
    let refNumber = null;
    let leadProvider = "";
    let reEnterID = null;
    let creditCardAction = null;
    let ad_publish_platform = null;
    let payment_capture_status = null;
    let fusion = false;
    let fbclid = null;
    let gclid = null;

    if (adParam && adParam.utm_campaign) {
        AdId =  adParam.utm_campaign; /// facebook adid
    }

    if (adParam && adParam.utm_source) {
        ad_publish_platform = adParam.utm_source;
    }
    if (adParam && adParam.utm_source) {
        UtmSource = adParam.utm_source;
    }


    if (adParam && adParam.utm_campaign) { // UTMID
        UtmCampaign = adParam.utm_campaign;
    }
    if (adParam && adParam.utm_id) { // UTMID
        UtmId = adParam.utm_id;
    }
    if (adParam && adParam.utm_medium) { // UTMMEDIUM
        UtmMedium = adParam.utm_medium;
    }
    if (adParam && adParam.utm_content) { // UTMCONTENT
        UtmContent = adParam.utm_content;
    }

    if (adParam && adParam.utm_term) { // UTMTERM
        UtmTerm = adParam.utm_term;
    }

    if (adParam && adParam.utm_source_platform) { // UTMSOURCEPLATFORM
        UtmSourcePlatform = adParam.utm_source_platform;
    }

    if (adParam && adParam.utm_creative_format) { // UTMCREATIVEFORMAT
        UtmCreativeFormat = adParam.utm_creative_format;
    }

    if (adParam && adParam.utm_marketing_tactic) { // UTMMARKETINGTACTIC
        UtmMarketingTactic = adParam.utm_marketing_tactic;
    }

    if (adParam && adParam.utm_marketing_objective) { // UTMMARKETINGOBJECTIVE
        UtmMarketingObjective = adParam.utm_marketing_objective;
    }

    if (adParam && adParam.utm_lead_provider) {
        leadProvider = adParam.utm_lead_provider;
    }
    if (adParam && adParam.refNumber){
        refNumber = adParam.refNumber.toString();
    }
    if (adParam && adParam.sessionID){
        reEnterID = adParam.sessionID.toString();
    }
    if (adParam && adParam.ccAction){
        creditCardAction = adParam.ccAction.toString();
    }
    if (adParam && adParam.success){
        payment_capture_status = adParam.success.toString();
    }

    if (adParam && adParam.gclid){ // gclid
        gclid = adParam.gclid;
    }

    if (adParam && adParam.fbclid){ // gclid
        fbclid = adParam.fbclid;
    }

    //check if querystring exist. If not assign values
    function isObjEmpty (obj) {
        return Object.keys(obj).length === 0;
    }

   if(isObjEmpty(adParam)){
      UtmSource ="Metropolitan Website";
      UtmSourcePlatform = "Metropolitan Website";
      UtmMedium = "direct";
   }



    if (adParam && adParam.type){
        if (adParam.type.toString() === "fusion") {
            fusion = true;
        }

    }
    /*let authToken = localStorage.getItem("mmih_auth_token");
    let aName = 'N/A';
    let aNumber = 'N/A';
    let aChannel = 'Digital Direct';
    let sChannel = 'Met GetUp Website';
    let eRef = 'Metgetup';
    let env = null;

    let hst = window && window.location && window.location.hostname;
    if (hst === "www.atommetgetup.co.za") {
        env = 'production'
    } else {
        env = 'sandbox'
    }*/



    /*const ADDITIONAL_LIFE = {
        uniqueLifeIdentifier: "",
        firstName: "",
        lastName: "",
        age: "",
        relation: "",
        gender: "",
        province: "",
        medicalAid: "",
        coverAmount: 0.00,
        premium: 0.0,
        dependentOnParents: true,
        disability: false,
        fullTimeStudent: false,
        maritalStatus: "",
        lifeType: "",
        previousCoverAmount: 0.00
    }*/

    const INITIAL_STATE = {
        app_state: {
            id: null,
            additionalLifeIndex: -1,
            GUPCount: 0,
            GULPCount: 0,
            GUCCount: 0,
            GUCLCount: 0,
            GUEFCount: 0,
            currentStep: "/",
            beenToSummary: false,
            SESSION_ID : uuid(),
            refNumber: refNumber,
            reEnterID: reEnterID,
            performBankValidation: true,      //// enable cpb bank val
            performPersonValidation: true,    //// enable cpb person val
            performOTPValidation: true,      //// enable OTP
            planType: "Yourself",
            creditCardAction: creditCardAction,
            paymentCaptureStatus: payment_capture_status,
            fusion: fusion,
            inceptionDate: UtilService.getFirstDayOfNextMonth(),
            enteredOTP: "",
            otpStatus: false,
            rating: {
                starRating: 0
            },
            allowFlexiblePaymentOptions: true,
            allowRating: false,
            flexiblePaymentOption: null,
            bankingValidationFailureCount: 0,
            idValidationFailureCount: 0,
            getupClientNumber: null,
        },
        root_state: {
            payAtRef: "",
            quotePackageId: "",
            cashBack: false,
            cashbackPremium: 0.0,
            coverForLife: false,
            coverForLifePremium: 0.0,
            inflation: 6,
            valueProtectionBenefit: true,
            requote: false,
            totalPremiumForInsuredLives: 0.0,
            totalBasePremiumForInsuredLives: 0.00,
            totalCoverForInsuredLives: 0.0,
            totalLifeHackPremium: 0.0,
            additionalInfo: {
                channel: "Digital Direct",
                subChannel: "Met GetUp Website",
                externalReference: "Metgetup",
                adId: AdId,
                utmId: UtmId,
                utmCampaign: UtmCampaign,
                utmMedium: UtmMedium,
                utmContent: UtmContent,
                utmTerm: UtmTerm,//--new ad id
                utmSource  : UtmSource,
                utmSourcePlatform: UtmSourcePlatform,
                utmCreativeFormat: UtmCreativeFormat,
                utmMarketingTactic: UtmMarketingTactic,
                UtmMarketingObjective: UtmMarketingObjective,
                gclid: gclid,
                fbclid: fbclid,
                ad_publish_platform: ad_publish_platform,
                leadProvider: leadProvider,
                leadID: ""
            },
            mainMember: {
                uniqueIdentifier: uuid(),
                rolePlayerId: 1,
                title: "",
                firstName: "",
                lastName: "",
                initials: "",
                email: "",
                cellphone: "",
                idNumber: "",
                gender: "",
                age: "",
                dateOfBirth: null,
                maritalStatus: "",
                medicalAid: false,
                income: "",
                employed: true,
                industryOfEmployment: "",
                 educationLevel: "",
                premium: 0.00,
                basePremium: 0.00,
                lifeHackPremium: 0.00,
                coverAmount: null,
                lifeType: "GUPO",
                thirdPartyQuotedPremium: 0.00,
                thirdPartyCoverAmountBand: "",
                knownConsumerNumber: null,
                contactDetails: [],
                fullAddress:"",
                streetNumber:"",
                streetName:"",
                complexNumber:"",
                complexName:"",
                suburb:"",
                town:"",
                province:"",
                postalCode:""
            },
            additionalLives: [

            ],
            pageHistories: [],
            paymentMethod: "Debit Order",
            billingDay: 31,
            paymentReminderDay: null,
            bankDetails: {
                accountHolder: "",
                bank: "",
                branchCode: "",
                accountType: "",
                accountNumber: ""
            },
            beneficiaries: [
                {
                    firstName: "",
                    lastName: "",
                    dob: null,
                    relation: "",
                    cellphone: "",
                    gender: ""
                }
            ],
            contactPreference: "Email",
            preferredContactTime: "12h00 - 13h00",
            contactMe: true,
            shareMyData: true,
            whatsappConsent: false,
            isPip: false,
            acceptDisclaimer: false,
            policyNumber: "",
            zendeskTicketNumber: "",
            error: null,
            validated: null,
            webChatTicket: "",
            replacements: [],
            webChatTicketDateRequested: ""
        }
    };

    const [state, setState] = useState(INITIAL_STATE); // Our context is a state hook ;)
    return <Context.Provider value={[state, setState]}>{props.children}</Context.Provider>;
};

export {Context, StateContext};
